import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Frontend",
        tab2 = "Backend",
        tab3 = "Databases",
        tab4 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab className="text-white">{tab1}</Tab>
                                        <Tab className="text-white">{tab2}</Tab>
                                        <Tab className="text-white">{tab3}</Tab>
                                        <Tab className="text-white">{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="#">UI/UX<span> - Design</span></a>
                                                    React.js, Material UI, Bootstrap.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                           <li>
                                                <a href="#">Amazon Web Services<span> - (AWS)</span></a>
                                                Lambda, API Gateway, S3, SQS, SNS, CloudFormation, Cognito, and CodeCommit.
                                                </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                           <li>
                                                <a href="#">SQL/NOSQL</a>
                                                    PGSQL and MongoDB
                                                </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="#">BSc CS<span> - Middlesex University, London</span></a> 2021 - 2024
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;