import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | Entrepreneur | Software Engineer </title>
                    <meta name="description" content="Discover the dynamic world of Taha Farooqui, a multifaceted entrepreneur, software engineer, and distinguished expert in blockchain and AI technologies. Explore his innovative contributions and insights that bridge the realms of business and cutting-edge technology" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
