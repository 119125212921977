import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Customized Software Development',
        description: 'I craft tailored software solutions to meet your unique business needs, ensuring optimal functionality and a seamless user experience.'
    },
    {
        icon: <FiLayers />,
        title: 'Blockchain Integration Services',
        description: 'I bring expertise in integrating blockchain technology into your business processes, enhancing security, transparency, and efficiency.'
    },
    {
        icon: <FiUsers />,
        title: 'AI-Powered Insights',
        description: 'I leverage artificial intelligence to provide actionable insights, empowering your decision-making and driving business intelligence.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Digital Transformation Solutions',
        description: 'I guide businesses through strategic digital transformations, optimizing processes for efficiency and fostering sustainable growth.'
    },
    {
        icon: <FiUsers />,
        title: 'Cybersecurity Measures',
        description: 'I fortify your digital assets with robust cybersecurity services, offering threat detection and risk mitigation for comprehensive protection.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Tech Consultancy',
        description: 'I provide strategic tech insights and consultancy services, helping you navigate the dynamic tech landscape for successful project planning and execution.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
