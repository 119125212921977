const BlogContent = [
    {
        images: '01',
        title: 'AI Meets Blockchain: A Powerful Duo for Tomorrows Tech Landscape',
        category: 'AI/Blockchain'
    },
    {
        images: '02',
        title: 'The Medoczen Apps AI-Driven Approach to Wellness',
        category: 'Healthcare'
    },

    {
        images: '03',
        title: 'Fleetbites Innovative AI-Powered Food Delivery Experience',
        category: 'Food and Tech'
    }
]

export default BlogContent;